import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TimeSpanInputComponent} from './time-span-input.component';
import {FlexModule} from '@ngbracket/ngx-layout';
import {FormsModule} from '@angular/forms';
import {PadPipe} from '@core/pipes/pad.pipe';


@NgModule({
    declarations: [
        TimeSpanInputComponent
    ],
    exports: [
        TimeSpanInputComponent
    ],
    imports: [
        CommonModule,
        FlexModule,
        FormsModule,
        PadPipe
    ]
})
export class TimeSpanInputModule {
}
