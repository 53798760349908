import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'pad',
    standalone: true
})
export class PadPipe implements PipeTransform {
    transform(value: string | number | undefined, length: number, fill?: string): string {
        if (!value) {
            value = '';
        }

        return value.toString().padStart(length, fill);
    }
}
