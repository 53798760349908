import {Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {coerceBoolean} from '@core/decorators/coerce-boolean';
import {hoursToMilliseconds, hoursToMinutes, hoursToSeconds, minutesToHours, minutesToMilliseconds, minutesToSeconds} from 'date-fns';
import moment from 'moment';

export type TimeSpanFormat = 'ms' | 's' | 'm' | 'h';

@Component({
    selector: 'omt-time-span-input',
    templateUrl: './time-span-input.component.html',
    styleUrls: ['./time-span-input.component.scss']
})
export class TimeSpanInputComponent implements OnInit, OnChanges {
    @ViewChild('daysInput') daysInput: ElementRef<HTMLInputElement>;
    @ViewChild('hoursInput') hoursInput: ElementRef<HTMLInputElement>;
    @ViewChild('minutesInput') minutesInput: ElementRef<HTMLInputElement>;
    @coerceBoolean()
    @Input() withDays = false;
    @Input() initializeWithCurrentTime = true;
    @Input() timeSpanFormat: TimeSpanFormat = 'ms';
    @Input() timeSpan = 0;
    @Output() timeSpanChange = new EventEmitter<number>();
    format: string;

    days: number | null;
    hours: number | null;
    minutes: number | null;

    ngOnInit(): void {
        this.initialize(this.initializeWithCurrentTime);
        this.setFormat();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.withDays) {
            this.setFormat();
        }
    }

    initialize(withCurrentTime: boolean): void {
        if (!this.timeSpan && withCurrentTime) {
            (this.days as unknown as string) = '0' + 0;
            const current = new Date();
            this.hours = current.getHours();
            this.minutes = current.getMinutes();
        } else {
            const durationAsDate = moment.duration(this.timeSpan, this.timeSpanFormat);
            this.days = durationAsDate.days();
            this.hours = this.withDays ? durationAsDate.hours() : durationAsDate.days() * 24 + durationAsDate.hours();
            this.minutes = durationAsDate.minutes();
        }

        this.setTimeSpan();
    }

    onChange(value: number, changed: 'days' | 'hours' | 'minutes'): void {
        console.log('changed', value);
        this.formatValues(value, changed);
        this.setTimeSpan();
        this.emit();
    }

    emit(): void {
        this.timeSpanChange.emit(this.timeSpan);
    }

    private formatValues(value: number, changed: 'days' | 'hours' | 'minutes'): void {
        switch (changed) {
            case 'days':
                this.days = this.enforceDoubleDigits(value ?? 0);
                if (this.daysInput) {
                    this.daysInput.nativeElement.value = this.days.toString();
                }
                break;
            case 'hours':
                this.hours = this.enforceDoubleDigits(value ?? 0);
                if (this.hoursInput) {
                    this.hoursInput.nativeElement.value = this.hours.toString();
                }
                break;
            case 'minutes':
                this.minutes = this.enforceDoubleDigits(value ?? 0);
                if (this.minutesInput) {
                    this.minutesInput.nativeElement.value = this.minutes.toString();
                }
                break;
            default:
                throw new Error('Undefined value change');
        }
    }

    private enforceDoubleDigits(value: number): number {
        if (value.toString().length > 2) {
            const stringValue = value.toString();
            const finalValue = stringValue.at(0) + stringValue.at(stringValue.length - 1);
            return +finalValue;
        }

        return value;
    }

    private setFormat(): void {
        this.format = this.withDays ? 'dd:hh:mm' : 'hh:mm';
    }

    private setTimeSpan(): void {
        let days: number;
        let hours: number;
        let minutes: number;
        switch (this.timeSpanFormat) {
            case 'ms':
                days = hoursToMilliseconds(this.days * 24);
                hours = hoursToMilliseconds(this.hours);
                minutes = minutesToMilliseconds(this.minutes);
                break;
            case 's':
                days = hoursToSeconds(this.days * 24);
                hours = hoursToSeconds(this.hours);
                minutes = minutesToSeconds(this.minutes);
                break;
            case 'm':
                days = hoursToMinutes(this.days * 24);
                hours = hoursToMinutes(this.hours);
                minutes = +this.minutes;
                break;
            case 'h':
                days = this.days * 24;
                hours = this.hours;
                minutes = minutesToHours(this.minutes);
                break;
            default:
                throw new Error('Unsupported time format.');
        }

        this.timeSpan = (this.withDays ? days : 0) + hours + minutes;
    }
}
