import {defer, MonoTypeOperatorFunction, Observable, Subject} from 'rxjs';
import {finalize, tap} from 'rxjs/operators';

export const tapOnce = <T>(fn: (value) => void): MonoTypeOperatorFunction<T> => (source: Observable<any>) =>
    defer(() => {
        let first = true;
        return source.pipe(
            tap<T>((payload) => {
                if (first) {
                    fn(payload);
                }

                first = false;
            })
        );
    });

/**
 * Invokes a callback upon subscription.
 *
 * @param callback function to invoke upon subscription
 * @returns stream which will invoke callback
 */
export const prepare = <T>(
    callback: () => void
): (source: Observable<T>) => Observable<T> => (source: Observable<T>): Observable<T> =>
    defer(() => {
        callback();
        return source;
    });

/**
 * Indicates whether the observable is currently loading (meaning subscription is active and
 * it hasn't completed or errored).
 *
 * @param indicator subject as target for indication
 * @returns stream which will indicate loading through passed subject
 */
export const indicate = <T>(
    indicator: Subject<boolean>
): (source: Observable<T>) => Observable<T> => (source: Observable<T>) =>
    source.pipe(
        prepare(() => indicator.next(true)),
        finalize(() => indicator.next(false))
    );

